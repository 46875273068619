import { defineStore } from "pinia";
import axios from "axios";
import { useCartStore } from "~/store/cart";
import * as Sentry from "@sentry/vue";

export const useCheckoutStore = defineStore("checkoutStore", {
    persist: {
        storage: persistedState.cookiesWithOptions({
            sameSite: "strict",
            maxAge: 60 * 60 * 12, // 12 hours
        }),
    },
    state: () => ({
        curStep: 1,
        contact: {
            name: "",
            email: "",
            emailConfirm: "",
            phone: "",
        },
        shippingAddress: {
            name: "",
            orgName: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            county: "",
            useToCreateAccount: false,
        },
        payment: {
            id: "",
            paymentProfileId: "", // Authorize.net payment profile ID
            paymentType: "", // cc, ach, or saved payment ID (User table)... rename type to paymentType?
            setAsDefault: false,
            // CC
            cardNumber: "",
            cardholderName: "",
            expMonth: "",
            expYear: "",
            cvv: "",
            saveCard: false,
            // ACH
            accountHolderName: "",
            accountNumber: "",
            accountNumberConfirm: "",
            routingNumber: "",
            accountType: "",
            agreeToTermsAndConditions: false,
            saveACH: false,
            // Billing address
            useShippingAddress: false,
            street: "",
            city: "",
            state: "",
            zip: "",
        },
        order: {
            items: [],
            total: 0,
        },
        placeOrderEnabled: false,

        showModal: false,
        placeOrderErrorMsg: "",
    }),
    getters: {
        completedStep1: (state): boolean => {
            const data = state.contact;
            if (data.name && data.email && data.phone) {
                return true;
            }
            return false;
        },
        completedStep2: (state): boolean => {
            const data = state.shippingAddress;
            if (data.name && data.orgName && data.street && data.city && data.state && data.zip) {
                return true;
            }
            return false;
        },
    },
    actions: {
        updateContactInfo(obj: any) {
            this.contact = obj;
        },
        updateOrder(obj: any) {
            this.order = obj;
        },
        updateShippingAddress(obj: any) {
            this.shippingAddress = obj;
        },
        updatePayment(obj: any) {
            this.payment = obj;
        },
        updatePlaceOrderEnabled(val: boolean) {
            this.placeOrderEnabled = val;
        },
        updateCurStep(val: number) {
            this.curStep = val;
        },
        clearEnteredData() {
            this.contact = {
                name: "",
                email: "",
                emailConfirm: "",
                phone: "",
            };
            this.shippingAddress = {
                name: "",
                orgName: "",
                street: "",
                city: "",
                state: "",
                zip: "",
                county: "",
                useToCreateAccount: false,
            };
            this.payment = {
                id: "",
                paymentProfileId: "",
                paymentType: "",
                setAsDefault: false,
                cardNumber: "",
                cardholderName: "",
                expMonth: "",
                expYear: "",
                cvv: "",
                saveCard: false,
                accountHolderName: "",
                accountNumber: "",
                accountNumberConfirm: "",
                routingNumber: "",
                accountType: "",
                agreeToTermsAndConditions: false,
                saveACH: false,
                useShippingAddress: false,
                street: "",
                city: "",
                state: "",
                zip: "",
            };
        },
        async placeOrder() {
            // ACH validation
            if (this.payment?.accountNumber && this.payment.accountNumber !== this.payment.accountNumberConfirm) {
                this.placeOrderErrorMsg = "Account number does not match.";
                this.showModal = true;
                return;
            }

            this.placeOrderEnabled = false;

            // update the billing info with the shipping info if they're the same
            let billingInfo = this.payment;
            if (this.payment.useShippingAddress) {
                billingInfo = {
                    ...billingInfo,
                    // take the address info from the shipping address and update the billing info

                    street: this.shippingAddress.street,
                    city: this.shippingAddress.city,
                    state: this.shippingAddress.state,
                    zip: this.shippingAddress.zip,
                };
            }

            const data = {
                contact: this.contact,
                shipping: this.shippingAddress,
                billing: billingInfo,
                order: this.order,
            };

            this.placeOrderErrorMsg = "";
            try {
                // post the order
                const config = useRuntimeConfig();
                const route = config.public.STATAMIC_API + "/store/orders";

                const result = await axios.post(route, data);

                if (result.status === 200) {
                    this.clearEnteredData();
                    useCartStore().qty = 0; // Cart has been cleared. Set qty to zero.

                    const id = result.data.id;
                    const email = result.data.contact_email;
                    navigateTo(`/checkout/confirmation?id=${id}&email=${email}`);
                }
            } catch (e: any) {
                console.log("error:", e);
                if (e.response?.data.message) {
                    this.placeOrderErrorMsg = e.response.data.message;
                } else {
                    this.placeOrderErrorMsg = e.message;
                }
                this.showModal = true;
                Sentry.captureException(e);
            }
        },
    },
});
