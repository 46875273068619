<script lang="ts" setup>
import { STATES } from "@/constants/states";
import { onMounted, ref } from "vue";
import SelectTag from "~/components/form/SelectTag.vue";
import { useUserAuth } from "~/composables/userAuth";
import { useCartStore } from "~/store/cart";
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";

// Properties

// Data
const config = useRuntimeConfig();

const { logout } = useUserAuth();

const route = useRoute();
const router = useRouter();

const { isLoggedIn } = storeToRefs(useUserStore());

const showDropDownAccount = ref(false);
const showDropDownMore = ref(false);
const showDropDownHamburger = ref(false);
const curState = ref("ga");
const cartQty = computed(() => useCartStore().qty);

// Functions
async function setUserLocation(state = "") {
    if (state) {
        useUserStore().updateUserState(state);
        navigateTo("/home/" + state.toLowerCase());
    } else {
        curState.value = await useUserStore().fetchUserState();

        // Redirect if on home page
        if (route.path == "/" && curState.value) {
            navigateTo("/home/" + curState.value);
        }
    }
}

const toggleAccountDropdown = () => {
    showDropDownHamburger.value = false;
    showDropDownMore.value = false;
    showDropDownAccount.value = !showDropDownAccount.value;
};
const toggleMoreDropdown = () => {
    showDropDownHamburger.value = false;
    showDropDownAccount.value = false;
    showDropDownMore.value = !showDropDownMore.value;
};
const toggleHamburgerDropdown = () => {
    showDropDownAccount.value = false;
    showDropDownMore.value = false;
    showDropDownHamburger.value = !showDropDownHamburger.value;
};

const catalogNavLink = ref("/catalog");
const isMounted = ref(false);

onMounted(async () => {
    await setUserLocation();
    await useUserStore().fetchUser(false); // Fetch user data in case page is manually refresh
    await useCartStore().fetchCartAndSummary();

    // Add event listener to close dropdown menu
    document.getElementById("main-nav")?.addEventListener(
        "blur",
        (e) => {
            showDropDownAccount.value = false;
            showDropDownHamburger.value = false;
        },
        true,
    );

    catalogNavLink.value = curState.value ? `/catalog/${curState.value}` : "ga";

    setTimeout(() => {
        isMounted.value = true;
    }, 1000);
});

watch(
    () => route.fullPath,
    () => {
        useUserStore().updateLoginRedirect(route.fullPath);
    },
);
</script>

<template>
    <header class="fixed top-0 z-50 w-full border-b border-gray-50 bg-white drop-shadow print:hidden">
        <!-- Desktop -->
        <div class="container hidden py-6 md:flex">
            <NuxtLink :to="curState ? `/home/${curState}` : '/'" class="inline-block flex-none pr-2 lg:pr-8">
                <NuxtImg
                    src="/images/ABC_logo-mobile_112x80.png"
                    format="webp"
                    class="max-h-14 lg:max-h-20"
                    alt="ABC Logo"
                />
            </NuxtLink>

            <div class="flex-1">
                <!-- Nav links -->
                <div class="relative mb-10 w-full">
                    <ul id="main-nav" class="text-right uppercase" tabindex="0">
                        <li class="relative float-right text-xs">
                            <span class="cursor-pointer" @click="toggleAccountDropdown">
                                My Account <NuxtImg src="/images/fa/chevron-down-regular.svg" class="inline w-3" />
                            </span>
                            <!-- Dropdown - My Account -->
                            <ul
                                v-show="showDropDownAccount"
                                id="dropdown-account"
                                class="dropdown-menu right-0 top-7 w-fit"
                            >
                                <li
                                    v-if="isLoggedIn"
                                    class="cursor-pointer"
                                    @click="
                                        router.push('/account/details');
                                        toggleAccountDropdown();
                                    "
                                >
                                    <NuxtImg src="/images/header/account_details.svg" class="mr-2 inline" />
                                    Account Details
                                </li>
                                <li
                                    v-if="isLoggedIn"
                                    class="cursor-pointer"
                                    @click="
                                        router.push('/account/my-orders');
                                        toggleAccountDropdown();
                                    "
                                >
                                    <NuxtImg src="/images/header/account_orders.svg" class="mr-2 inline" />
                                    My Orders
                                </li>
                                <li
                                    v-if="isLoggedIn"
                                    @click="
                                        router.push(isLoggedIn ? '/account/my-wishlist' : '/login');
                                        toggleAccountDropdown();
                                    "
                                >
                                    <NuxtImg src="/images/header/account_orders.svg" class="mr-2 inline" />
                                    My Wishlist
                                </li>
                                <li
                                    v-if="isLoggedIn"
                                    class="cursor-pointer"
                                    @click="
                                        router.push('/account/submit-a-po');
                                        toggleAccountDropdown();
                                    "
                                >
                                    <NuxtImg src="/images/header/account_po.svg" class="mr-2 inline" />
                                    Submit a PO
                                </li>
                                <li v-if="isLoggedIn" class="cursor-pointer" @click="logout">
                                    <NuxtImg src="/images/header/account_logout.svg" class="mr-2 inline" />
                                    Log Out
                                </li>
                                <!-- Not logged in yet -->
                                <li
                                    v-if="!isLoggedIn"
                                    class="cursor-pointer"
                                    @click="
                                        router.push('/login');
                                        toggleAccountDropdown();
                                    "
                                >
                                    Login
                                </li>
                                <li
                                    v-if="!isLoggedIn"
                                    class="cursor-pointer"
                                    @click="
                                        router.push('/create-account');
                                        toggleAccountDropdown();
                                    "
                                >
                                    Create Account
                                </li>
                            </ul>
                        </li>
                        <li>
                            <NuxtLink to="/Podcast">Podcast</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink to="/articles">Blog</NuxtLink>
                        </li>
                        <li><a href="https://coursewave.com" target="_blank">CourseWave</a></li>
                        <li>
                            <NuxtLink to="/about-abc">About ABC</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="catalogNavLink">Get Catalog</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink :to="curState ? `/products/state/${curState}/page/1` : '/products/page/1'">
                                Shop All
                            </NuxtLink>
                        </li>
                    </ul>
                </div>
                <!-- Location / Search / Cart -->
                <div class="clear-both ml-2 flex text-right">
                    <!-- Location -->
                    <SelectTag
                        v-model="curState"
                        :rounded-corners="true"
                        class="w-52 flex-none"
                        @change="setUserLocation(curState)"
                    >
                        <option
                            v-for="(val, key) in STATES"
                            :key="'state-' + key.toLowerCase()"
                            :value="key.toLowerCase()"
                        >
                            {{ val }}
                        </option>
                    </SelectTag>

                    <!-- Search -->
                    <SearchBox :cur-state="curState" />
                    <!-- Shopping Cart -->
                    <div class="relative ml-6 flex-none pt-1.5 md:ml-12">
                        <nuxt-link to="/cart">
                            <NuxtImg src="/images/fa/cart-shopping-light.svg" class="-mt-1 mr-6 w-9" />
                            <div
                                class="absolute rounded-full bg-abc-red text-center text-[10px] text-white drop-shadow-lg"
                                :class="cartQty >= 10 ? '-top-2 right-3 h-6 w-6' : '-top-2 right-4 h-5 w-5 leading-5'"
                            >
                                <span v-if="cartQty > 99">99+</span>
                                <span v-else>{{ cartQty }}</span>
                            </div>
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>

        <!-- Mobile -->
        <div id="main-nav-mobile" class="relative mt-6 flex h-14 text-gray-700 md:hidden">
            <div class="ml-4 w-12 flex-none overflow-hidden text-center">
                <NuxtImg
                    v-show="!showDropDownHamburger"
                    src="/images/fa/bars-light.svg"
                    alt="Hamburger Menu"
                    class="w-9"
                    @click="toggleHamburgerDropdown"
                />
                <NuxtImg
                    v-show="showDropDownHamburger"
                    src="/images/fa/rectangle-xmark-light.svg"
                    alt="Close Menu"
                    class="w-9"
                    @click="toggleHamburgerDropdown"
                />
            </div>
            <div class="relative w-12 flex-none overflow-hidden text-center">
                <NuxtImg src="/images/fa/location-dot-light.svg" class="mx-auto h-6 w-6" />
                <hr class="border-gray-5 00 mx-auto w-1/2" />
                <div class="text-sm uppercase">{{ curState }}</div>
                <select
                    v-model="curState"
                    class="absolute left-0 top-0 h-12 w-full appearance-none bg-transparent text-transparent focus:outline-none"
                    @change="setUserLocation(curState)"
                >
                    <option v-for="(val, key) in STATES" :key="key" :value="key">{{ val }}</option>
                </select>
            </div>
            <div class="flex-1">
                <NuxtLink :to="curState ? `/home/${curState}` : '/'">
                    <NuxtImg
                        src="/images/ABC_logo-mobile_112x80.png"
                        format="webp"
                        class="mx-auto -mt-2 h-11"
                        alt="ABC Logo"
                    />
                </NuxtLink>
            </div>
            <div
                class="ml-6 w-10 flex-none text-center"
                :class="showDropDownAccount ? 'border-b-4 border-gray-700' : ''"
            >
                <NuxtImg
                    v-if="isLoggedIn"
                    src="/images/fa/user-light.svg"
                    class="mt-0.5 inline w-9"
                    @click="toggleAccountDropdown"
                />
                <NuxtLink v-else to="/login">
                    <NuxtImg src="/images/fa/user-light.svg" class="mt-0.5 inline w-9" />
                </NuxtLink>
            </div>
            <div class="relative mr-4 w-14 flex-none text-right">
                <nuxt-link to="/cart">
                    <NuxtImg src="/images/fa/cart-shopping-light.svg" class="mt-2 inline w-10" />
                    <div
                        class="absolute rounded-full bg-abc-red pt-0.5 text-center text-[10px] text-white drop-shadow-lg"
                        :class="
                            cartQty >= 10 ? '-right-1.5 -top-1 h-6 w-6 leading-5' : '-right-1 -top-0 h-5 w-5 leading-4'
                        "
                    >
                        <span v-if="cartQty > 99">99+</span>
                        <span v-else>{{ cartQty }}</span>
                    </div>
                </nuxt-link>
            </div>

            <!-- Mobile: Hamburger Dropdown-->
            <ul v-show="showDropDownHamburger" class="dropdown-menu-underline top-[57px] w-full">
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push(curState ? `/products/state/${curState}/page/1` : '/products/page/1');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        Shop All
                    </div>
                </li>
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push('/catalog');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        Get Catalog
                    </div>
                </li>
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push('/about-abc');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        About ABC
                    </div>
                </li>
                <li @click="toggleHamburgerDropdown()">
                    <div>
                        <a href="http://coursewave.com" target="_blank" class="block">
                            <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                            CourseWave
                        </a>
                    </div>
                </li>
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push('/articles');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        Blog
                    </div>
                </li>
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push('/podcast');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        Podcast
                    </div>
                </li>
                <li
                    @click="
                        toggleHamburgerDropdown();
                        router.push(!isLoggedIn ? '/login' : '/account/my-wishlist');
                    "
                >
                    <div>
                        <NuxtImg src="/images/fa/chevron-right-regular.svg" class="float-right h-6" />
                        My Wishlist
                    </div>
                </li>
            </ul>
            <!-- Mobile: Account Dropdown-->
            <ul v-show="showDropDownAccount" class="dropdown-menu top-[57px] w-full">
                <li
                    @click="
                        router.push('/account/details');
                        toggleAccountDropdown();
                    "
                >
                    <NuxtImg src="/images/header/account_details.svg" class="mr-2 inline" />
                    Account Details
                </li>
                <li
                    @click="
                        router.push('/account/my-orders');
                        toggleAccountDropdown();
                    "
                >
                    <NuxtImg src="/images/header/account_orders.svg" class="mr-2 inline" />
                    My Orders
                </li>
                <li
                    @click="
                        router.push('/account/submit-a-po');
                        toggleAccountDropdown();
                    "
                >
                    <NuxtImg src="/images/header/account_po.svg" class="mr-2 inline" />
                    Submit a PO
                </li>
                <li @click="logout">
                    <NuxtImg src="/images/header/account_logout.svg" class="mr-2 inline" />
                    Log Out
                </li>
            </ul>
        </div>
        <!-- Search -->
        <SearchBox :cur-state="curState" class="md:hidden" />
    </header>
</template>

<style scoped>
#main-nav > ul {
    @apply m-0 list-none overflow-hidden p-0;
}

#main-nav > li {
    @apply float-right border-r text-xs;
}

#main-nav > li > a,
#main-nav > li > span {
    @apply mx-1 block px-1 py-1 text-center hover:border-b-4 hover:border-primary-darker lg:mx-2 lg:px-3;
}

#main-nav > li:first-child {
    @apply border-r-0;
}

.dropdown-menu,
.dropdown-menu-underline {
    @apply absolute z-50 overflow-hidden border border-gray-300 bg-white text-left;
}

.dropdown-menu ul,
.dropdown-menu-underline ul {
    @apply m-0 list-none overflow-hidden p-0;
}

.dropdown-menu li {
    @apply px-4 py-3 hover:bg-primary-darker hover:text-white md:w-64;
}

.dropdown-menu-underline li {
    @apply px-4 hover:bg-primary-darker hover:text-white md:w-64;
}

.dropdown-menu-underline li div {
    @apply border-b py-3;
}
</style>
